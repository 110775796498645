import classes from './FeePaymentModal.module.css';
import cross from '../../../../../Assets/Logo/crose-white.svg';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const FeePaymentModal = ({ onClose, applicationFeeData, showModeofPaymentDropdown = false }) => {

    const [formattedDate, setFormattedDate] = useState('');
    const [internalDate, setInternalDate] = useState('');

    const formatDateToMMDDYYYY = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const convertToInputDateFormat = (mmddyyyyDate) => {
        const [month, day, year] = mmddyyyyDate.split('/');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (applicationFeeData.DateofDeposite) {
            const initialDate = convertToInputDateFormat(applicationFeeData.DateofDeposite);
            setInternalDate(initialDate);
            setFormattedDate(applicationFeeData.DateofDeposite);
        }
    }, [applicationFeeData.DateofDeposite]);

    const handleDateChange = (event) => {
        const inputDate = event.target.value;
        const formattedDate = formatDateToMMDDYYYY(inputDate);
        setFormattedDate(formattedDate);
        setInternalDate(inputDate);
        applicationFeeData.DateofDeposite = formattedDate;
    };

    return (
        <>
            <div className={classes["fee-modal-block"]}>
                <div className={classes["fee-modal-header"]}>
                    <div className={classes["heading"]}>Fee Details</div>
                    <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={onClose} /></div>
                </div>
                <div className={classes["fee-modal-body"]}>
                    <Form>
                        <fieldset disabled>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Fee Type</Form.Label>
                                <Form.Control type="text" placeholder="" value={applicationFeeData.FeeType} />
                            </Form.Group>

                            {!showModeofPaymentDropdown && (
                                <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Mode of Payment</Form.Label>
                                <Form.Control type="text" placeholder="" value={applicationFeeData.modeofPayment} />
                            </Form.Group>
                            )}
                            {showModeofPaymentDropdown && (
                                <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Mode of Payment</Form.Label>
                                <Form.Select id="">
                                    <option>Mode of payment</option>
                                </Form.Select>
                            </Form.Group>
                            )}

                            <Form.Group className={`${classes["amount-field"]} mb-3`}>
                                <Form.Label htmlFor="disabledTextInput">Amount</Form.Label>
                                <Form.Control type="text" placeholder="Amount" value={applicationFeeData.FeeAmount} className={classes["amount"]} />
                                <div className={classes["currency"]}>{applicationFeeData.currency}</div>
                            </Form.Group>

                            {/* Hidden actual date input */}
                            <Form.Control type="date" value={internalDate} onChange={handleDateChange} hidden />
                            {/* Visible MM/DD/YYYY input */}
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Date of Deposit</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formattedDate} 
                                    onChange={handleDateChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Payment Schedule</Form.Label>
                                <Form.Control type="text" placeholder="" value={applicationFeeData.FeeBasis} />
                            </Form.Group>
                        </fieldset>
                        <div className={classes["make-payment-button-block"]}><button type="submit" className={`${classes["payment-btn"]} primary-button`}>Make Payment</button></div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default FeePaymentModal;