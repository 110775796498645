import axiosInstance from "./Interceptor";

const ApiService = {
  signIn: async (data) => {
    try {
      const response = await axiosInstance.post('/auth/signin', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getMobOtp: async (phoneNumber) => {
    try {
      const response = await axiosInstance.get(process.env.REACT_APP_2FACTOR_AUTH_URL + phoneNumber + '/AUTOGEN/MSMAgentTest');
      return response;
    } catch (error) {
      throw error.error.Details;
    }
  },

  validateMobOtp: async (session,otp) => {
    try {
      const response = await axiosInstance.post(process.env.REACT_APP_2FACTOR_AUTH_URL+'/VERIFY/' + session + '/' + otp);
      return response;
    } catch (error) {
      console.log(error)
      throw error.response.data;
    }
  },

  getEmailOtp: async (data) =>{
    try {
      const response = await axiosInstance.post('/user/send-code',data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }

  },

  validateEmailOtp: async (data) =>{
    try {
      const response = await axiosInstance.post('/user/validate-code',data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }

  },

  signInV1: async (data) => {
    try {
      const response = await axiosInstance.post('/auth/signinV1', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  userRegistration:async (data) => {
    try {
      const response = await axiosInstance.post('/user/register-user', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  userForgotPassword : async (data) => {
    try {
      const response = await axiosInstance.post('user/forgot-password', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
  checkToken:async (token) => {
    try {
      const response = await axiosInstance.get('/user/token-status?token='+token);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  resetPassword:async (data) => {
    try {
      const response = await axiosInstance.post('/user/reset-password',data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  countryList:async (data) => {
    try {
      const response = await axiosInstance.get('/user/countryList');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  checkEmail:async (data) => {
    try {
      const response = await axiosInstance.get('/user/check-email?email='+data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  checkMobileNo:async (data) => {
    try {
      const response = await axiosInstance.post('/user/check-mobileNumber', {
        mobileNumber: data
      });
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
}
export default ApiService;