import classes from './ApplicationSubmit.module.css';
import emailIcon from '../../../../../Assets/Logo/email-icon.svg';
import phoneIcon from '../../../../../Assets/Logo/contact-icon.svg';
import cross from '../../../../../Assets/Logo/cross-icon.svg';
import redCross from '../../../../../Assets/Logo/red-cross-icon.svg';
import { Button, Card, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../GlobleShared/Loader/Loader';
import ApplicationService from '../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import useLocalStorage from '../../../../GlobleShared/CustomHooks/useLocalStorage';
import ProfileService from '../../../../../Services/ProfileService';
import SearchModal from '../../Search/SearchModal/SearchModal';
import FeePaymentModal from '../FeePaymentModal/FeePaymentModal';
import UploadPendingDocumentsModall from '../UploadPendingDocumentsModall/UploadPendingDocumentsModall';
import ConfirmationModal from '../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';

const ApplicationSubmit = () => {
    const initial = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [applicationData, setApplicationData] = useState({});
    const [feeData, setFeeData] = useState([]);
    const [applicationFeeData, setApplicationFeeData] = useState({});
    const [applicationFeeAmount, setApplicationFeeAmount] = useState();
    const [studentData, setStudentData] = useState({});
    const [feeAndDocumentStatusData, setFeeAndDocumentStatusData] = useState({});
    const [userDetail] = useLocalStorage('userDetail');
    const { applicationId } = useParams();
    const [feeModalIsVisible, setFeeModalIsVisible] = useState(false);
    const [uploadDocumentsModalIsVisible, setUploadDocumentsModalIsVisible] = useState(false);
    const [modalSize, setModalSize] = useState('');
    const [showFeeBreakup, setShowFeeBreakup] = useState(false);
    const [confirmationPopupFlag, setConfirmationPopupFlag] = useState(false);
    const [isSubmitApplication, setIsSubmitApplication] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [validationModalIsVisible, setValidationModalIsVisible] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [hintText, setHintText] = useState("");

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            if (applicationId) {
                getApplicationData(applicationId);
                getFeeAndDocumentStatus(applicationId);
            }
            if (userDetail.refId) {
                getStudentData(userDetail.refId);
            }
        }
    }, [applicationId, userDetail.refId]);

    const getApplicationData = (id) => {
        setIsLoading(true)
        ApplicationService.viewV1Application(id)
            .then((res) => {
                if (res) {
                    setApplicationData(res);
                    getApplicationFee(res);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getFeeAndDocumentStatus = (id) => {
        setIsLoading(true)
        ApplicationService.feeAndDocumentStatus(id)
            .then((res) => {
                if (res) {
                    setFeeAndDocumentStatusData(res);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getStudentData = (id) => {
        setIsLoading(true);
        ProfileService.getAboutProfileInfo(id).then((res) => {
            setStudentData(res);
            setIsLoading(false);
        }).catch((error) => {
            console.log('error: ', error);
            setIsLoading(false);
        });
    }

    const getApplicationFee = (res) => {
        const data = {
            ApplicationId: applicationId,
            IntakeId: res?.programInfo?.IntekId,
            Nationality: res?.genInfo?.Citizenship
        };
        setIsLoading(true);
        ApplicationService.forApplication(data)
            .then((res) => {
                setFeeData(res);
                setApplicationData(prevData => {
                    getApplicationFeeDetails(res, prevData);
                    return prevData;
                });
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getApplicationFeeDetails = (responseData, applicationData) => {
        const applicationFee = responseData.find(fee => fee.FeeType === "Application Fee");
        if (applicationFee) {
            applicationFee.ApplicationId = applicationId ? applicationId : '';
            applicationFee.currency = applicationData?.programInfo?.InstCurrency || 'NA';
            applicationFee.modeofPayment = 'Stripe';
            applicationFee.DateofDeposite = new Date().toLocaleDateString('en-US');
        }
        const appFeeAmount = applicationFee ? applicationFee.FeeAmount : 'NA';
        setApplicationFeeData(applicationFee);
        setApplicationFeeAmount(appFeeAmount);
    };

    const backToApplicationList = () => {
        setIsSubmitApplication(false);
        setConfirmationText("Are you sure you want to go back to the Application List?");
        setConfirmationPopupFlag(true);
    }

    const feePaymentHandle = () => {
        setModalSize('md');
        setFeeModalIsVisible(true);
    }

    const hidefeeModalhandler = () => {
        setFeeModalIsVisible(false);
        setModalSize('');
    }

    const uploadDocumentsHandle = () => {
        setModalSize('xl');
        setUploadDocumentsModalIsVisible(true);
    }

    const hideUploadDocumentModalhandler = () => {
        setUploadDocumentsModalIsVisible(false);
        setModalSize('');
    }

    const submitDocuments = (isDocumentPending) => {
        setUploadDocumentsModalIsVisible(false);
        setModalSize('');
        if (isDocumentPending) {
            getFeeAndDocumentStatus(applicationId);
        }
        else {
            return;
        }
    }

    const handleFeeBreakup = () => {
        setShowFeeBreakup(true);
        if (showFeeBreakup) {
            setShowFeeBreakup(false);
        }
    }

    const submitApplicationHandler = () => {

        // if (feeAndDocumentStatusData.ApplcaitionFeeStatus === 'Pending' &&
        //     feeAndDocumentStatusData.DocumentsStatus === 'Pending') {
        //     setModalSize('md');
        //     setValidationModalIsVisible(true);
        //     // toast.error("Application fee and documents are still pending. Please complete them before submitting.");
        //     return;
        // }

        if (feeAndDocumentStatusData.DocumentsStatus === 'Pending') {
            setModalSize('md');
            setAlertText("Oops! Document(s) is Pending!");
            setHintText("Documents are still pending. Please complete them before submitting.");
            setValidationModalIsVisible(true);
            return;
        }

        else if (feeAndDocumentStatusData.ApplcaitionFeeStatus === 'Pending') {
            setModalSize('md');
            setAlertText("Oops! Application Fee(s) is Pending!");
            setHintText("Application fee is still pending. Please complete it before submitting.");
            setValidationModalIsVisible(true);
            return;
        }

        else {
            setIsSubmitApplication(true);
            setConfirmationText("After submission, you can't change any information in this application. Make sure all information is correct and verified!");
            setConfirmationPopupFlag(true);
        }
    };

    const getConfirmationHandler = () => {
        setIsLoading(true);
        if(isSubmitApplication){
            ApplicationService.submitApplication(applicationId)
            .then((res) => {
                toast.success(res.message);
                setConfirmationText("");
                setConfirmationPopupFlag(false);
                getApplicationData(applicationId);
                setIsLoading(false);
            })
            .catch((error) => {
                setConfirmationText("");
                setConfirmationPopupFlag(false);
                setIsLoading(false);
                toast.error(error?.message);
            });
        }
        else{
            navigate('/member/application/list');
        }
    }

    const closeConfirmationModal = () => {
        setConfirmationText("");
        setConfirmationPopupFlag(false);
    }

    const validationCloseHandler = () => {
        setModalSize('')
        setValidationModalIsVisible(false);
    }

    return (
        <>

            {feeModalIsVisible && (
                <SearchModal onShow={feeModalIsVisible} size={modalSize}>
                    <FeePaymentModal onClose={hidefeeModalhandler} applicationFeeData={applicationFeeData} />
                </SearchModal>
            )}
            {uploadDocumentsModalIsVisible && (
                <SearchModal onShow={uploadDocumentsModalIsVisible} size={modalSize}>
                    <UploadPendingDocumentsModall onClose={hideUploadDocumentModalhandler} applicationId={applicationId} programId={applicationData.programInfo.ProgramId}
                        onSubmitPendingDocuments={submitDocuments} countryId={applicationData?.genInfo?.Citizenship} />
                </SearchModal>
            )}
            {validationModalIsVisible && (
                <SearchModal onShow={validationModalIsVisible} size={modalSize}>
                    <div className={classes["validation-modal-block"]}>
                        <div className={classes["validation-modal-header"]}>
                            <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={validationCloseHandler} /></div>
                        </div>
                        <div className={classes["validation-modal-body"]}>
                            <div className={classes["validation-modal-body-icon"]}>
                                <img src={redCross} alt="Red Cross" />
                            </div>
                            <div className={classes["validation-modal-alert-text"]}>{alertText}</div>
                            <div className={classes["validation-modal-hint"]}>{hintText}</div>
                            {/* <div className={classes["validation-modal-text"]}>You’ll have to wait until the payment goes through</div>
                            <div className={classes["validation-modal-hint"]}>Ensure your Credit Card information is correct and up-to-date</div> */}
                        </div>
                    </div>
                </SearchModal>
            )}
            {confirmationPopupFlag && (
                <ConfirmationModal onShow={confirmationPopupFlag} btnText="Yes" confirmationText={confirmationText} onClose={closeConfirmationModal} getConfirmation={getConfirmationHandler} />
            )}
            <div className={classes["application-submit-container"]}>
                <div className={classes["application-submit-section"]}>
                    <div className={classes["section-1"]}>
                        <div className={classes["application-submit-heading"]}>
                            Application for
                            {studentData.FirstName && studentData.LastName
                                ? ` ${studentData.FirstName} ${studentData.LastName} `
                                : 'NA'}
                            ({applicationData?.genInfo?.ProgramType || 'NA'})
                        </div>
                        <div className={classes["application-list-button"]}>
                            <button className={`${classes["back-to-appList"]} secondary-button`} onClick={backToApplicationList}>Go to Application List</button>
                        </div>
                    </div>
                    <div className={classes["section-2"]}>
                        <div className={classes["student-detail-section"]}>
                            <div className={classes["student-name"]}>{studentData.FirstName && studentData.LastName ? `${studentData.FirstName} ${studentData.LastName}` : 'NA'}</div>
                            <div className={classes["student-personal-details"]}>
                                <div className={classes["student-data"]}>
                                    MSM ID: {userDetail.refId ? `${userDetail.refId}` : 'NA'}
                                </div>
                                <div className={classes["detail-block"]}>
                                    <div>
                                        <img src={emailIcon} alt="email" />
                                    </div>
                                    <div className={classes["student-data"]}>{studentData.Email ? `${studentData.Email}` : 'NA'}</div>
                                </div>
                                <div className={classes["detail-block"]}>
                                    <div>
                                        <img src={phoneIcon} alt="phone" />
                                    </div>
                                    <div className={classes["student-data"]}>+{studentData?.MobileNoCountryCode} {studentData?.MobileNo}</div>
                                </div>
                            </div>
                        </div>
                        {applicationData?.genInfo?.SubmitoAdminDate === null && (
                            <div className={classes["application-submit-button"]}>
                            <button className={`${classes["submit-btn"]} primary-button`} onClick={submitApplicationHandler}>Submit Now</button>
                        </div>
                        )}
                    </div>
                    <div className={classes["section-3"]}>
                        <div className={classes["application-detail-heading"]}>Application Details</div>
                        <div className={classes["application-detail-table"]}>
                            <Table responsive className={classes.customTable}>
                                <thead>
                                    <tr>
                                        <th>Program</th>
                                        <th>Intake</th>
                                        <th>Institution</th>
                                        <th>Application Fee</th>
                                        <th>Documents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{applicationData?.programInfo?.ProgramName ? `${applicationData?.programInfo?.ProgramName}` : 'NA'}</td>
                                        <td>{applicationData?.programInfo?.IntakeName ? `${applicationData?.programInfo?.IntakeName}` : 'NA'}</td>
                                        <td>{applicationData?.programInfo?.InstName ? `${applicationData?.programInfo?.InstName}` : 'NA'}</td>
                                        <td>
                                            <div className={classes["action-block"]}>
                                                {feeAndDocumentStatusData.ApplcaitionFeeStatus === 'Success' ? (
                                                    <div className={classes["pending-success-alert"]}>{feeAndDocumentStatusData.ApplcaitionFeeStatus}</div>
                                                ) :
                                                    (
                                                        <>
                                                            <div>Fee to pay: {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} {applicationFeeAmount}</div>
                                                            <div><button className={`${classes["payment-modal-btn"]} primary-button`} onClick={feePaymentHandle}>Make Payment</button></div>
                                                            <div><Button variant="link" onClick={handleFeeBreakup} className={`${classes["fee-breakup-drop-down"]} text-danger`}>View Fee Breakup</Button></div>
                                                        </>

                                                    )
                                                }

                                            </div>
                                        </td>
                                        <td><div className={classes["action-block"]}>
                                            <div className={feeAndDocumentStatusData.DocumentsStatus === 'Success' ? classes["pending-success-alert"] : classes["pending-alert"]}>{feeAndDocumentStatusData.DocumentsStatus ? `${feeAndDocumentStatusData.DocumentsStatus}` : ''}</div>
                                            {feeAndDocumentStatusData.DocumentsStatus && (feeAndDocumentStatusData.DocumentsStatus === "Pending") && (
                                                <div><button className={`${classes["upload-modal-btn"]} primary-button`} onClick={uploadDocumentsHandle}>Upload</button></div>
                                            )}
                                        </div></td>
                                    </tr>
                                    {/* <tr>
                                <td colSpan={columns.length + 1} className={classes.noData}>
                                    No Data Available
                                </td>
                            </tr> */}
                                </tbody>
                            </Table>
                            {showFeeBreakup && (
                                <div className={classes["fee-breakup-card-wrapper"]}>
                                    <Card className={classes["fee-breakup-card"]}>
                                        <Card.Header className={classes["fee-breakup-card-header"]}>Fee Breakup</Card.Header>
                                        <Card.Body className={classes["fee-breakup-card-body"]}>
                                            <Card.Text className="m-0">
                                                <div className={classes["fee-breakup-sections"]}>
                                                    <div className={classes["fee-breakup-title"]}>Application Fee</div>
                                                    <div className={classes["fee-breakup-title"]}>
                                                        {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} {applicationFeeData.FullAmount ? `${applicationFeeData.FullAmount}` : '0'}
                                                    </div>
                                                </div>
                                                <div className={classes["fee-breakup-sections"]}>
                                                    <div className={classes["fee-breakup-subtitle"]}>{applicationFeeData.WaiverAmt ? 'Application fee waiver applied' : 'no waiver fee'}</div>
                                                    <div className={classes["fee-breakup-subtitle"]}> - {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} {applicationFeeData.WaiverAmt ? `${applicationFeeData.WaiverAmt}` : '0'}</div>
                                                </div>
                                            </Card.Text>
                                            <hr className={classes["fee-breakup-divider"]} />
                                            <Card.Text className="m-0">
                                                <div className={classes["fee-breakup-sections"]}>
                                                    <div className={classes["fee-breakup-title"]}>Discount Fee</div>
                                                    <div className={classes["fee-breakup-title"]}>
                                                        {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} {applicationFeeAmount ? `${applicationFeeAmount - applicationFeeData.WaiverAmt}` : '0'}
                                                    </div>
                                                </div>
                                                <div className={classes["fee-breakup-sections"]}>
                                                    <div className={classes["fee-breakup-subtitle"]}>{applicationFeeData.CouponId ? 'Coupon code applied' : 'No coupon code applied'}</div>
                                                    <div className={classes["fee-breakup-subtitle"]}> - {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} 0</div>
                                                </div>
                                            </Card.Text>
                                            <hr className={classes["fee-breakup-divider"]} />
                                            <Card.Text className="m-0">
                                                <div className={classes["fee-breakup-sections"]}>
                                                    <div className={classes["fee-breakup-title"]}>Fee to Pay</div>
                                                    <div className={classes["fee-breakup-title"]}>
                                                        {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} {applicationFeeAmount}
                                                    </div>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default ApplicationSubmit;